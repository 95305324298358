<template lang="pug">
div
  UniversalLink(:link='article.url' class='text-black hover:no-underline')
    div(v-if='article.image' class='relative')
      img(
        :key='src'
        :src='src'
        :alt='article.image.title'
        :width='imageWidth'
        :height='imageHeight'
        loading='lazy'
        class='w-full'
      )
    h2(class='text-xl font-bold leading-none xs:text-lg lg:text-xl') {{ article.title }}
    p {{ truncate(article.description) }}
</template>

<script>
export default defineNuxtComponent({
  name: 'BlogCard',
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageWidth() {
      return this.$device.isMobile ? 296 : 432
    },
    imageHeight() {
      return this.$device.isMobile ? 222 : 324
    },
    src() {
      // Image format from storyblok
      // https://a.storyblok.com/f/148322/800x600/185e1a0f23/overlanding-thumbnail.jpg/m/296x222
      return this.$storyblok.formatSrc(`${this.article.image.filename}/m/${this.imageWidth}x${this.imageHeight}`)
    },
  },
  methods: {
    truncate(str) {
      let truncatedStr = str
      if (str.length > 160) truncatedStr = `${str.slice(0, 160).trim()}...`
      return truncatedStr
    },
  },
})
</script>
